<template>
  <div
    class="v-w-100 v-d-flex"
    :class="[asColumn ? 'v-flex-column' : 'v-flex-row']"
  >
    <label
      v-if="!hideLabel"
      class="for-form-control"
    >
      <span v-html="label" />
      <span
        v-if="required"
        class="v-required"
      />
    </label>
    <div
      v-for="(option, index) in options"
      :key="`${uid}-${index.toString()}`"
      class="v-radio-single"
      :class="[asColumn ? ' v-mb-sm' : 'v-mr-sm']"
    >
      <div class="v-required-radio-block">
        <input
          :id="`v-input-radio-${uid}-${index.toString()}`"
          class="v-input-radio"
          :aria-label="label.toString()"
          :value="option"
          type="radio"
          v-model="picked"
        />
        <label
          class="v-pointer"
          :for="`v-input-radio-${uid}-${index.toString()}`"
        >
          <span class="v-radio" />
          <slot
            :value="option"
            name="option"
          />
        </label>
      </div>
      <div
        v-if="$slots.additional"
        class="v-additonal-block-radio"
      >
        <slot
          :value="option"
          name="additional"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { VElement } from '@arora/common'

const { selected } = defineProps<
  VElement & {
    selected: T
    options: T[]
    asColumn?: boolean
    hideLabel?: boolean
  }
>()
const emit = defineEmits(['update:selected'])

const uid = useId()

const picked = computed<T>({
  get() {
    return selected
  },
  set(value) {
    emit('update:selected', value)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-input-radio {
  display: none;

  + label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;

    .v-radio {
      border: 2px solid variables.$BodyTextColorLight;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
  }

  &:checked + label {
    .v-radio {
      border: 2px solid variables.$PrimaryBackgroundColor;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        content: '';
        background: variables.$PrimaryBackgroundColor;
      }
    }
  }
}

.v-radio-single {
  label {
    padding-left: 0;
    margin-bottom: 0;
  }
}
</style>
